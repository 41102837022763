.item{
    color: black;
    border: solid 1px;
    border-radius: 7px;
    padding: 7px;
    margin: 7px;

    &__img{
        border-radius: 5%;
        border: solid 1px black;
        width: 150px;
    
    }

    &__title{
        padding-top: 10px;
        font-size: 16px;
        overflow-wrap: normal;
        max-width: 150px;
        height: 50px;
    }

    &__price{
        font-size: 18px;
    }
}