.itemListContainer{
    margin: 100px;
    padding: 40px;
    text-align: center;
    background-color: whitesmoke;
    color: azure;
    font-size: larger;

    &__titulo{
        color: black;
    }
}


@media (max-width: 800px) {
    
    .itemListContainer{
        margin: 0;
        margin-top: 70px;
        padding: 0;
    }
  }