
.cartForm{
    position: absolute;
    right: calc(50vw - 250px);
    top: 25vh;
    width: 80vw;
    max-width: 500px;
    padding: 25px;
    border-radius: 20px;
    background-color: aquamarine;

    &__container{

        max-width: 100vw !important;
        position: absolute;
        top: 0;
        
        height: 100%;
        height: calc(100vh);
        background-color: rgba(255, 166, 0, 0.192);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}


@media (max-width: 500px) {
    .cartForm{
        padding-top: 40%;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
    }
}