

.itemDetail{
    color: black;
    margin: 5px;

    &__img{
        width: 350px;
    }

    &__col{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title{
        height: 40px;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 0;
    }

    &__price{
        text-align: center;
        font-size: 18px;
    
    }

    &__descripcion{
        padding-top: 15px;
    }

    &__btnTerminarCompra{
    
        min-width: 240px;
        border: 1px solid black;
        background-color: rgba(122, 7, 103, 0.739);
        color: white;
        
        &:hover{
            background-color: white;
            color: rgba(122, 7, 103, 0.739);
            transition: 400ms;
        }
    } 
}

@media (max-width: 800px) {

    .itemDetail{

        &__img{
            margin: auto;
        }

        &__price {
            padding: 0;
        }
    }
}