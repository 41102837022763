
.navbar-collapse{
    justify-content: center;
}

.bg-dark {
    background-color: rgb(0, 0, 0)!important;
}

h1 {
    font-size: 20px;
}
  
body {
    font-family: 'Ubuntu', sans-serif;
}

.navbar-brand {
    display: flex;
}
