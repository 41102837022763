
.cartWidget{

    &__container {
        width: 22px;
        height: 22px; 
    }

    &__container img {
        width: 100%; 
    }

    &__circuloRojo {
    
        color: white;
        font-weight: bolder;
        font-size: 14px;
        text-align: center;
        background-color: red;
        position: relative;
        right: -90%;
        bottom: 150%;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
}
