
.cart{
    width: 100vw;

    &__resumen{
        text-align: center;
        width: 80vw;
        max-width: 900px;
        margin: 100px auto 0 auto;
        padding-top: 15px;
        background-color: rgb(241, 241, 241);
        border-radius: 2vw;
    }

    &__totalContainer{
        border-top: 1px solid gray;
        height: 100px;
        align-content: space-between;
        margin: 0 auto 150px auto;
        width: 80%;
    }


    &__total{

        display: flex;
        margin-top: 3%;
        align-items: center;
        justify-content: flex-end;
    
        &--larger{
            font-size: larger;
        }
    }

    &__btnContainer{

        align-content: center;
        justify-content: space-evenly;
        
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: gray;
        margin: 0;
    }

    &__button{

        $widthButton: 250px;
        $heightButton: 50px;
    
        width: $widthButton;
        height: $heightButton;
        border-radius: calc($heightButton / 2);
        line-height: initial;
        
        &--seguirComprando, &--finalizarCompra{
            background-color: white;
            border: 1px solid black;
    
            &:hover{
                background-color: rgba(122, 7, 103, 0.739);
                color: white;
            }
        }
    
        &--finalizarCopra{
            position: relative;
            left: calc(50vw - $widthButton/2);
            bottom: -25px;
            background-color: red;
            color: white;
            border: red;
    
            &:hover{
                background-color: rgb(207, 0, 0);
                transition: 100ms;
            } 
        }
    }
}


@media (max-width: 768px) {

    .cart__resumen{
        width: 100vw;
    }

    .cart__button{
        width: 150px;
        height: 40px;
    }
}

